<template>
  <Html
    :dir="head.htmlAttrs.dir"
    :lang="head.htmlAttrs.lang"
  >
    <Head>
      <Title>{{ title }}</Title>
    </Head>
    <Body :class="theme">
      <UiComponentWrapper id="app">
        <MoleculeAlert
          v-if="bannerIsReady && showBanner"
          class="app__banner event"
          darker
          :theme="theme"
          @alert:close="
            track('Banner clicked', {
              category: 'Button',
              label: 'Event closed',
            });
            closeBanner();
          "
        >
          <AtomAnchor
            class="text"
            :to="data.componentEventBanner.callToActionLink"
          >
            {{
              data.componentEventBanner.message
            }}
          </AtomAnchor>
          <AtomAnchor
            v-if="data.componentEventBanner.callToActionLink && data.componentEventBanner.callToActionText"
            class="highlighted"
            type="text"
            :to="data.componentEventBanner.callToActionLink"
            @click="
              track('Banner clicked', {
                category: 'Button',
                label: 'Event clicked',
              })
            "
          >
            {{ data.componentEventBanner.callToActionText }}
            <UiComponentIcon
              theme="dark"
              type="arrow-right"
            />
          </AtomAnchor>
        </MoleculeAlert>

        <!-- Section > Layout -->
        <UiComponentWrapper class="app__layout">
          <UiComponentWrapper
            class="app__layout-header"
            :class="[
              theme,
              {
                scrolled: hasScrolled,
                slide: !navIsVisible,
              },
            ]"
          >
            <UiComponentWrapper class="inner">
              <UiComponentContainer :class="{ opened: navIsOpened }">
                <OrganismNav
                  :theme="theme"
                  @nav:opened="onToggleNavigation"
                />
              </UiComponentContainer>
              <UiComponentWrapper class="progress">
                <UiComponentWrapper class="bar" />
              </UiComponentWrapper>
            </UiComponentWrapper>
          </UiComponentWrapper>
          <UiComponentWrapper class="app__layout-body">
            <slot />
          </UiComponentWrapper>
          <UiComponentWrapper
            class="app__layout-footer"
            :class="theme"
          >
            <UiComponentContainer>
              <MoleculeBreadcrumb
                v-if="listItems.length > 1"
                :list="listItems"
                :theme="theme"
              />
              <OrganismFooter :theme="theme" />
            </UiComponentContainer>
          </UiComponentWrapper>
        </UiComponentWrapper>

        <!-- Modal > Demo - Step 1 -->
        <Transition
          @enter="onEnter"
          @leave="onLeave"
        >
          <UiComponentModal
            v-if="forms.demo.isVisible && !forms.demo.hasBeenSubmitted"
            key="modal-demo"
            class="app__modal demo"
            :theme="theme"
            @modal:close="forms.demo.isVisible = false"
          >
            <template #title>
              {{ demoForm.firstStepTitle }}
            </template>
            <template #description>
              {{ demoForm.firstStepText }}
            </template>
            <template #content>
              <UiComponentWrapper class="content">
                <UiComponentWrapper class="left">
                  <UiComponentImage
                    alt="Background"
                    class="background"
                    :expected-size="530"
                    :height="2355"
                    orientation="portrait"
                    source="https://livestorm.imgix.net/1127/1646403148-blue-pattern.jpg"
                    :width="1590"
                  />
                  <UiComponentHeading
                    as="paragraph"
                    :level="2"
                  >
                    Need a Livestorm demo?
                  </UiComponentHeading>
                  <UiComponentHeading
                    as="paragraph"
                    :level="3"
                  >
                    Need a Livestorm demo?
                  </UiComponentHeading>
                  <UiComponentParagraph
                    class="description"
                    size="s"
                  >
                    Leave your information to schedule a demo with a product expert.
                    We will run you through the features and answer your
                    questions.
                  </UiComponentParagraph>
                  <UiComponentWrapper class="testimonial">
                    <UiComponentImage
                      alt="Logo"
                      class="logo"
                      :expected-size="18"
                      :extra-params="{ bri: 100 }"
                      :height="56"
                      orientation="portrait"
                      source="https://livestorm.imgix.net/1127/1618317822-klaviyo.png"
                      :width="244"
                    />
                    <UiComponentParagraph
                      class="quote"
                      size="s"
                    >
                      "We were looking for a simple webinar software that was not
                      over-engineered. If you do a side-by-side feature comparison,
                      Livestorm competes very well with the other webinar
                      players."
                    </UiComponentParagraph>
                    <UiComponentWrapper class="author">
                      <UiComponentImage
                        alt="Author"
                        class="picture"
                        :expected-size="32"
                        :height="400"
                        source="https://livestorm.imgix.net/1127/1505223008-clement.jpg"
                        :width="400"
                      />
                      <UiComponentParagraph class="name">
                        Michel Palaref
                      </UiComponentParagraph>
                      <UiComponentParagraph class="job">
                        Account Manager
                      </UiComponentParagraph>
                    </UiComponentWrapper>
                  </UiComponentWrapper>
                  <UiComponentWrapper class="logos">
                    <UiComponentWrapper
                      v-for="i in 2"
                      :key="i"
                      class="row"
                    >
                      <UiComponentWrapper
                        v-for="j in 4"
                        :key="`${i}-${j}`"
                        class="cell"
                      >
                        <UiComponentImage
                          alt="Logo"
                          class="logo"
                          :expected-size="80"
                          :extra-params="{ bri: 100 }"
                          :height="56"
                          source="https://livestorm.imgix.net/1127/1618317822-klaviyo.png"
                          :width="244"
                        />
                      </UiComponentWrapper>
                    </UiComponentWrapper>
                  </UiComponentWrapper>
                </UiComponentWrapper>
                <UiComponentWrapper class="right">
                  <UiComponentWrapper class="headline">
                    <UiComponentImage
                      alt="Flag"
                      class="flag fr"
                      :expected-size="16"
                      :height="16"
                      orientation="portrait"
                      source="https://livestorm.imgix.net/1127/1646403152-flag-of-france.svg"
                      :width="24"
                    />
                    <UiComponentImage
                      alt="Flag"
                      class="flag uk"
                      :expected-size="16"
                      :height="16"
                      orientation="portrait"
                      source="https://livestorm.imgix.net/1127/1646403153-flag-of-the-united-kingdom.svg"
                      :width="24"
                    />
                    <UiComponentImage
                      alt="Flag"
                      class="flag us"
                      :expected-size="16"
                      :height="16"
                      orientation="portrait"
                      source="https://livestorm.imgix.net/1127/1646403153-flag-of-the-united-states.svg"
                      :width="24"
                    />
                    <UiComponentParagraph size="s">
                      A Webinar Solution used by the best US companies for their
                      business
                    </UiComponentParagraph>
                  </UiComponentWrapper>
                  <UiComponentWrapper
                    class="options"
                    :class="theme"
                  >
                    <UiComponentWrapper
                      class="item"
                      :class="{ selected: currentFormIndex === 0 }"
                      @click="currentFormIndex = 0"
                    >
                      <UiComponentIcon
                        type="user"
                      />
                      <UiComponentWrapper class="description">
                        <UiComponentHeading
                          as="paragraph"
                          :level="6"
                        >
                          {{ data.componentForm.demoOptionTitle }}
                        </UiComponentHeading>
                        <UiComponentParagraph>
                          {{ data.componentForm.demoOptionText }}
                        </UiComponentParagraph>
                      </UiComponentWrapper>
                    </UiComponentWrapper>
                    <UiComponentWrapper
                      class="item"
                      :class="{ selected: currentFormIndex === 1 }"
                      @click="currentFormIndex = 1"
                    >
                      <UiComponentIcon
                        type="play"
                      />
                      <UiComponentWrapper class="description">
                        <UiComponentHeading
                          as="paragraph"
                          :level="6"
                        >
                          {{ data.componentForm.watchAVideoOptionTitle }}
                        </UiComponentHeading>
                        <UiComponentParagraph>
                          {{ data.componentForm.watchAVideoOptionText }}
                        </UiComponentParagraph>
                      </UiComponentWrapper>
                    </UiComponentWrapper>
                  </UiComponentWrapper>
                  <OrganismHubspotNativeForm
                    v-if="currentFormIndex === 0"
                    :form-id="demoForm.formId"
                    :theme="theme"
                    unique-id="form-demo-modal"
                    @hubspot:form:submitted="onSubmitted(demoForm, $event)"
                  />
                  <OrganismHubspotNativeForm
                    v-else
                    :form-id="watchVideoForm.formId"
                    :theme="theme"
                    unique-id="form-watch-a-video-modal"
                    @hubspot:form:submitted="onSubmitted(watchVideoForm, $event)"
                  />
                </UiComponentWrapper>
              </UiComponentWrapper>
            </template>
          </UiComponentModal>
        </Transition>

        <!-- Modal > Demo - Step 2 -->
        <Transition
          @enter="onEnter"
          @leave="onLeave"
        >
          <UiComponentModal
            v-if="forms.demo.isVisible && forms.demo.hasBeenSubmitted"
            key="modal-success"
            class="app__modal success"
            :theme="theme"
            @modal:close="forms.demo.isVisible = false"
          >
            <template #title>
              {{ demoForm.lastStepTitle }}
            </template>
            <template #content>
              <UiComponentWrapper class="content">
                <div
                  class="message"
                  v-html="$md.render(demoForm.lastStepText)"
                />
                <AtomButton
                  size="l"
                  :action="signupOrUpgradeCallToAction.action"
                  :theme="theme"
                  :to="signupOrUpgradeCallToAction.to"
                >
                  {{ signupOrUpgradeCallToAction.slot }}
                </AtomButton>
              </UiComponentWrapper>
            </template>
          </UiComponentModal>
        </Transition>

        <!-- Modal > Offer - Step 1 -->
        <Transition
          @enter="onEnter"
          @leave="onLeave"
        >
          <UiComponentModal
            v-if="forms.offer.isVisible && !forms.offer.hasBeenSubmitted"
            key="modal-offer"
            class="app__modal offer"
            :theme="theme"
            @modal:close="forms.offer.isVisible = false"
          >
            <template #content>
              <OrganismHubspotNativeForm
                ref="$offerForm"
                :form-id="offerForm.formId"
                :theme="theme"
                unique-id="form-offer-modal"
                @hubspot:form:ready="onReady"
                @hubspot:form:submitted="onSubmitted(offerForm, $event)"
              />
            </template>
          </UiComponentModal>
        </Transition>

        <!-- Modal > Offer - Step 2 -->
        <Transition
          @enter="onEnter"
          @leave="onLeave"
        >
          <UiComponentModal
            v-if="forms.offer.isVisible && forms.offer.hasBeenSubmitted"
            key="modal-download"
            class="app__modal download"
            :class="{
              'large': resource.coMarketing,
              'extra-large': !resource.coMarketing,
            }"
            :theme="theme"
            @modal:close="forms.offer.isVisible = false"
          >
            <template #content>
              <UiComponentHeading
                class="title"
                :level="2"
              >
                {{ data.componentForm.offerSuccessTitle }}
              </UiComponentHeading>
              <UiComponentWrapper class="cards">
                <OrganismContentCard
                  :contains="false"
                  class="resources"
                  :class="{ split: !resource.coMarketing }"
                  :content="resource"
                  display-on-modal
                  force-new-tab
                />
                <OrganismContentCard
                  v-if="!resource.coMarketing"
                  class="signup"
                  :contains="false"
                  :content="signupCard"
                  crop="top"
                  display-on-modal
                />
              </UiComponentWrapper>
            </template>
          </UiComponentModal>
        </Transition>

        <!-- Experience > CSM -->
        <OrganismCsm
          v-if="csmExperienceIsEnabled"
          :component-csm-section="data.componentCsmSection"
          :component-global="data.global"
        />

        <!-- Experience > Product Tour -->
        <OrganismProductTour v-if="productTourExperienceIsEnabled" />
      </UiComponentWrapper>
    </Body>
  </Html>
</template>

<script setup>
import { gsap } from 'gsap'

// Composables
const { track } = useAnalyticsJS()
const { listItems } = useBreadcrumb()
useChilipiper()
const { useDarkMode, useLightMode } = useColorScheme()
const { state } = useConditionalPreviewMode()
const { locale } = useI18n()
const { isLogged } = useIsLoggedIn()
const { $md } = useMarkdown()
const { isVisible: navIsVisible } = useNavigation()
const { resource } = useResource()
const route = useRoute()
const { public: publicRuntimeConfig } = useRuntimeConfig()
const theme = useState('color-scheme', () => 'light')

// Async Data
const { data } = await useAsyncData(
  `layout-default-${locale.value}`,
  async () => {
    return Promise.all([
      $fetch(`/api/content/layouts/default/${locale.value}`, { query: { preview: state.preview } }),
      $fetch('/api/content/layouts/rating-schema/en'),
    ])
  },
  {
    transform: ([layoutData, ratingSchema]) => {
      const priceCurrency = locale.value === 'en' ? 'USD' : 'EUR'
      const proPlansCount = layoutData.componentPricing.proColumnPrice.length - 1
      return {
        ...layoutData,
        softwareApplicationSchema: {
          ...ratingSchema,
          applicationCategory: 'CommunicationApplication',
          offers: {
            '@context': 'https://schema.org/',
            '@type': 'AggregateOffer',
            priceCurrency,
            'lowPrice': layoutData.componentPricing.freeColumnPrice,
            'highPrice':
              priceCurrency === 'USD'
                ? layoutData.componentPricing.proColumnPrice[proPlansCount]
                  .priceEurYearly
                : layoutData.componentPricing.proColumnPrice[proPlansCount]
                  .priceEurYearly,
            'offeredBy': {
              '@context': 'https://schema.org/',
              '@type': 'Organization',
              'name': 'Livestorm',
              'url': 'https://livestorm.co/',
            },
            'priceSpecification': [
              {
                '@context': 'https://schema.org/',
                '@type': 'PriceSpecification',
                'price': layoutData.componentPricing.freeColumnPrice,
                priceCurrency,
                'name': layoutData.componentPricing.freeColumnTitle,
                'eligibleQuantity': {
                  '@context': 'https://schema.org/',
                  '@type': 'QuantitativeValue',
                  'unitText': layoutData.componentPricing.freeColumnSubTitle,
                },
              },
              ...layoutData.componentPricing.proColumnPrice.map(data => ({
                '@context': 'https://schema.org/',
                '@type': 'PriceSpecification',
                'price':
                  priceCurrency === 'USD'
                    ? data.priceUsdYearly
                    : data.priceEurYearly,
                priceCurrency,
                'name': layoutData.componentPricing.proColumnTitle,
                'eligibleQuantity': {
                  '@context': 'https://schema.org/',
                  '@type': 'QuantitativeValue',
                  'unitText': layoutData.componentPricing.proColumnSubTitle.replace(
                    '%{currentActiveContacts}',
                    data.mac,
                  ),
                },
              })),
            ],
          },
        },
      }
    },
  },
)

// Refs
const $offerForm = ref(null)
const bannerHasBeenClosed = ref(false)
const bannerIsReady = ref(false)
const csmExperienceIsEnabled = ref(false)
const currentFormIndex = ref(0)
const hasScrolled = ref(false)
const hasTicked = ref(false)
const mutationObserver = ref(null)
const navIsOpened = ref(false)
const productTourExperienceIsEnabled = ref(false)

// States
const forms = useState('forms', () => {
  return data.value.allForms.reduce((accumulator, currentForm) => {
    return {
      ...accumulator,
      [currentForm.slug]: {
        hasBeenSubmitted: false,
        isVisible: false,
      },
    }
  }, {})
})

// Computed Properties
const demoForm = computed(() => {
  return data.value.allForms.find(({ slug }) => slug === 'demo')
})

const offerForm = computed(() => {
  return data.value.allForms.find(({ slug }) => slug === 'offer')
})

const organisationSchema = computed(() => {
  return {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    'url': publicRuntimeConfig.baseUrl,
    'logo': data.value.global.logo.url,
  }
})

const showBanner = computed(() => {
  return (
    data.value.componentEventBanner.activated
    && !bannerHasBeenClosed.value
  )
})

const signupCard = computed(() => {
  return {
    backgroundImage: data.value.componentForm.offerSuccessCoMarketing,
    callToAction: data.value.global.ctaTryForFreeShort,
    description: data.value.componentForm.offerSuccessText,
    event: {
      name: 'Signup clicked',
      attributes: {
        label: 'Thank you pop up CTA',
      },
    },
    to: `https://${publicRuntimeConfig.appBaseUrl}/#/signup`,
  }
})

const signupOrUpgradeCallToAction = computed(() => {
  return isLogged.value
    ? {
        action: 'Upgrade clicked',
        to: `https://${publicRuntimeConfig.appBaseUrl}/#/settings?page=settings&tab=billing`,
        slot: data.value.global.ctaUpgradeNow,
      }
    : {
        action: 'Signup clicked',
        to: `https://${publicRuntimeConfig.appBaseUrl}/#/signup`,
        slot: data.value.global.ctaGetStartedForFree,
      }
})

const title = computed(() => {
  return route.meta.title || 'Livestorm'
})

const watchVideoForm = computed(() => {
  return data.value.allForms.find(({ slug }) => slug === 'watch-video')
})

// Watchers
watch(
  () => forms.value,
  () => {
    toggleScrollOnBody()
  },
  { deep: true },
)

watch(
  () => route.name,
  (value) => {
    if (value.startsWith('webinar-tools')) {
      useDarkMode()
    }
    else {
      useLightMode()
    }
  },
  { immediate: true },
)

// Methods
function closeBanner() {
  bannerHasBeenClosed.value = true
}

function onEnter($el, done) {
  gsap.from(
    $el.querySelector('.background'),
    {
      autoAlpha: 0,
      duration: 0.6,
      ease: 'expo.out',
    },
  )
  gsap.from(
    $el.querySelector('.box'),
    {
      autoAlpha: 0,
      delay: 0.15,
      duration: 0.6,
      ease: 'expo.out',
      onComplete: () => {
        done()
      },
      scale: 0.9,
      y: 80,
    },
  )
}

function onLeave($el, done) {
  gsap.to(
    $el.querySelector('.box'),
    {
      autoAlpha: 0,
      duration: 0.3,
      ease: 'expo.in',
      scale: 0.9,
      y: 80,
    },
  )
  gsap.to(
    $el.querySelector('.background'),
    {
      autoAlpha: 0,
      delay: 0.075,
      duration: 0.3,
      ease: 'expo.in',
      onComplete: () => {
        done()
      },
    },
  )
}

function onReady() {
  $offerForm.value.setHiddenInputValueFor('ebook_name', resource.value.title)
}

function onScroll() {
  if (!hasTicked.value) {
    hasTicked.value = true
    window.requestAnimationFrame(() => {
      hasTicked.value = false
      hasScrolled.value = window.scrollY > 0
    })
  }
}

function onToggleNavigation(event) {
  navIsOpened.value = event
}

async function onSubmitted(currentForm, submittedValues) {
  // Trigger event to Google Tag Manager
  if (window.dataLayer && currentForm.gtmEvent) {
    const { v4: uuidv4 } = await import('uuid')
    dataLayer.push({ event: `${currentForm.gtmEvent}.${uuidv4()}` }) // Send event to GTM
  }

  // Trigger event to Segment
  const properties = currentForm.segmentWhitelistedProperties
    ? currentForm.segmentWhitelistedProperties
      .split(',')
      .map(value => value.trim())
      .reduce((accumulator, value) => {
        return {
          ...accumulator,
          [value]: submittedValues[value] ? submittedValues[value] : undefined,
        }
      }, {})
    : submittedValues

  await track(currentForm.segmentEvent || 'Form submitted', {
    ...properties,
    category: 'Form',
    form_id: currentForm.formId,
    label: currentForm.segmentLabel || currentForm.segmentEvent || 'Form submitted',
    locale: locale.value,
    title: window.document.title,
  })

  forms.value[currentForm.slug].hasBeenSubmitted = true

  if (currentForm.redirectUrl) {
    window.location = `${publicRuntimeConfig.baseUrl}${currentForm.redirectUrl}`
  }
}

function toggleScrollOnBody() {
  if (
    Object.values(forms.value).some(({ isVisible }) => isVisible)
    || navIsOpened.value
  ) {
    document.body.classList.add('no-scroll')
  }
  else {
    document.body.classList.remove('no-scroll')
  }
}

// Lifecycle Hooks
onBeforeUnmount(() => {
  if (mutationObserver.value) {
    mutationObserver.value.disconnect()
    mutationObserver.value = null
  }

  window.removeEventListener('scroll', onScroll)
})

onMounted(() => {
  bannerIsReady.value = true

  mutationObserver.value = new MutationObserver((mutationList) => {
    for (const mutation of mutationList) {
      if (
        mutation.type === 'attributes'
        && mutation.attributeName === 'class'
      ) {
        const { classList } = document.getElementById('app')
        csmExperienceIsEnabled.value = classList.contains('enable-csm-experience')
        productTourExperienceIsEnabled.value = classList.contains('enable-product-tour-experience')
      }
    }
  })
  mutationObserver.value.observe(document.getElementById('app'), {
    attributes: true,
    childList: false,
    subtree: false,
  })

  window.addEventListener('scroll', onScroll)
})

// SEO
const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true,
})

// Structured Data
useLdJson(organisationSchema.value)
useLdJson(data.value.softwareApplicationSchema)
</script>

<style lang="postcss" scoped>
#app {
  @apply flex-col items-center;

  .app__banner {
    &.event {
      @apply hidden;

      @screen md {
        @apply flex;
      }

      :deep(.highlighted) {
        @apply inline-flex items-center;

        &:hover {
          i {
            @apply translate-x-2 !important;
          }
        }

        i {
          @apply ml-2 transform translate-x-0 transition-transform duration-300 ease-in-out !important;
        }
      }
    }
  }

  .app__layout {
    @apply flex-col items-center;

    .app__layout-header {
      @apply sticky top-0 flex-col items-center w-full z-40 bg-transparent border-b border-transparent transform transition-all duration-600 ease-in-out;

      &.dark {
        &.scrolled {
          @apply bg-[#091316] border-[#0E1C20];
        }
      }

      &.light {
        &.scrolled {
          @apply bg-white border-grey-blue-200;
        }
      }

      &.slide {
        @apply -translate-y-full;
      }

      .inner {
        @apply relative justify-center;

        .container {
          &.opened {
            @apply bg-white;
          }
        }

        .progress {
          @apply absolute -bottom-[5px] h-[5px] w-full;

          .bar {
            @apply w-0 h-full bg-livestorm-blue-700;
          }
        }
      }
    }

    .app__layout-body {
      @apply flex-col items-center;
    }

    .app__layout-footer {
      @apply flex-col items-center;

      &.dark {
        @apply bg-[#091316];
      }

      &.light {
        @apply bg-white;
      }

      .container {
        @apply flex-col;
      }
    }
  }

  :deep(.app__modal) {
    &.dark {
      &.success {
        .box {
          .body {
            .content {
              .message {
                @apply text-winter-green-900;

                p {
                  a {
                    @apply text-winter-green-700;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.light {
      &.success {
        .box {
          .body {
            .content {
              .message {
                @apply text-winter-green-900;

                p {
                  a {
                    @apply text-livestorm-blue-700;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.demo  {
      .box {
        .body {
          .content {
            .left {
              @apply hidden;
            }

            .right {
              @apply flex-col;

              .headline {
                @apply hidden;
              }

              .options {
                @apply flex-col;

                @screen md {
                  @apply flex-row flex-wrap;
                }

                &.dark {
                  .item {
                    @apply bg-white border-winter-green-300;

                    &.selected {
                      @apply bg-winter-green-100 border-winter-green-600;
                    }
                  }
                }

                &.light {
                  .item {
                    @apply bg-white border-grey-blue-300;

                    &.selected {
                      @apply bg-livestorm-blue-100 border-livestorm-blue-600;
                    }
                  }
                }

                & + .native-form {
                  @apply mt-6;
                }

                .item {
                  @apply p-4 border cursor-pointer rounded;

                  @screen md {
                    width: calc((100% - 32px) / 2);
                  }

                  &.selected {
                    @apply cursor-default;
                  }

                  &:last-child {
                    @apply mt-4;

                    @screen md {
                      @apply mt-0 ml-8;
                    }
                  }

                  .icon {
                    @apply flex-shrink-0 mt-1 rounded-none;
                  }

                  .description {
                    @apply flex-col ml-2;

                    p {
                      @apply font-medium text-base leading-6;

                      & + p {
                        @apply mt-[2px] font-normal text-xs leading-[18px];
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.download {
      &.large {
        .box {
          @apply max-w-[752px];
        }
      }

      &.extra-large {
        .box {
          @screen lg {
            @apply max-w-[752px];
          }

          @screen xl {
            @apply max-w-[1208px];
          }
        }
      }

      .box {
        .body {
          .title {
            @apply text-center text-3xl leading-10;

            @screen md {
              @apply text-5xl leading-16;
            }
          }

          .cards {
            @apply flex-col;

            @screen xl {
              @apply flex-row;

              &:only-child {
                @apply justify-center;
              }
            }

            .content-card {
              @apply mt-6;

              @screen md {
                @apply mt-8;
                height: 264px;
              }

              @screen lg {
                @apply h-auto;
              }

              &:first-child {
                @apply mt-10;
              }

              .head {
                @apply hidden;

                @screen md {
                  @apply flex;
                  height: 264px;
                }

                @screen lg {
                  @apply w-full;
                  height: 246px;
                }

                picture {
                  @screen lg {
                    @apply w-full h-full;
                  }
                }
              }

              .body {
                .button {
                  &.no-style {
                    @apply hidden;
                  }
                }

                p {
                  @apply font-normal text-grey-blue-600 text-origin leading-7;
                }
              }

              &.resources {
                &.split {
                  @screen xl {
                    width: calc(1208px - 96px - 424px - 2.5rem);
                  }
                }

                .button {
                  @apply px-6 h-12 text-white bg-livestorm-blue-700 mt-4;
                }
                &:hover {
                  .button {
                    @apply text-white bg-livestorm-blue-800 !important;
                  }
                }
              }

              &.signup {
                @screen xl {
                  @apply flex-grow h-auto mt-10 ml-8;
                  width: 424px;
                }

                .body {
                  @screen xl {
                    @apply justify-between h-full pt-0;
                  }

                  p {
                    @screen md {
                      @apply flex !important;
                    }

                    @screen xl {
                      @apply mt-6;
                      display: -webkit-box !important;
                    }
                  }
                }

                .button {
                  @apply px-6 h-12 bg-grey-blue-200 text-winter-green-900 mt-4;
                }

                &:hover {
                  .button {
                    @apply text-winter-green-900 bg-grey-blue-300 !important;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.experience {
      &.with-banner {
        .box {
          > img {
            @screen xl {
              top: 24px;
              right: 28px;
            }
          }

          .body {
            .content {
              .right {
                .headline {
                  @screen xl {
                    @apply flex;
                  }
                }
              }
            }
          }
        }
      }

      &.with-small-title {
        .box {
          .body {
            .content {
              .left {
                .h2 {
                  @apply hidden;
                }

                .h3 {
                  @apply flex;
                }
              }
            }
          }
        }
      }

      &.with-fr-flag {
        .box {
          .body {
            .content {
              .right {
                .headline {
                  .flag {
                    &.fr {
                      @apply flex;
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.with-uk-flag {
        .box {
          .body {
            .content {
              .right {
                .headline {
                  .flag {
                    &.uk {
                      @apply flex;
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.with-us-flag {
        .box {
          .body {
            .content {
              .right {
                .headline {
                  .flag {
                    &.us {
                      @apply flex;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .box {
        @screen xl {
          @apply p-0 overflow-hidden;
          max-width: 1060px !important;
        }

        .head {
          @screen xl {
            @apply hidden;
          }
        }

        .body {
          @screen xl {
            @apply mt-0;
          }

          .content {
            @screen xl {
              @apply flex-row;
            }

            .left,
            .right {
              @screen xl {
                @apply flex-col w-1/2;
              }
            }

            .left {
              @screen xl {
                @apply relative flex min-h-[700px] bg-livestorm-blue-700 text-white p-10;
              }

              .background {
                @apply absolute inset-0 rounded-none z-0;
              }

              .h2,
              .h3 {
                @apply text-white z-10;
              }

              .h3 {
                @apply hidden;
              }

              .description {
                @apply font-medium text-white whitespace-normal mt-2 mb-18 leading-6 z-10;
              }

              .testimonial {
                @apply flex-col w-full mt-auto p-6 border-2 border-livestorm-blue-200 rounded-4xl z-10;
                background: rgba(255, 255, 255, 0.1);

                .logo {
                  @apply rounded-none;
                }

                .quote {
                  @apply text-grey-blue-100 whitespace-normal mt-4 leading-6;
                }

                .author {
                  @apply items-center w-full mt-4;

                  .picture {
                    @apply flex-shrink-0 border border-grey-blue-100 rounded-full;
                  }

                  .info {
                    @apply flex-col ml-3;

                    .name {
                      @apply font-medium text-base text-white leading-6;
                    }

                    .job {
                      @apply text-base text-grey-blue-100 leading-6;
                    }
                  }
                }
              }

              .logos {
                @apply flex-col w-full mt-12;

                .row {
                  @apply w-full justify-between mt-4;

                  &:first-child {
                    @apply mt-0;
                  }

                  .cell {
                    @apply justify-start items-center w-auto;

                    .logo {
                      @apply rounded-none;
                    }
                  }
                }
              }
            }

            .right {
              .headline {
                @screen xl {
                  @apply hidden justify-center w-full pl-10 pr-12 py-5 bg-grey-blue-200;
                }

                .flag {
                  @apply hidden flex-shrink-0 rounded-none mt-1 mr-2;
                }

                p {
                  @apply ml-2 font-medium text-green-900 whitespace-normal leading-6;
                }
              }

              .native-form,
              iframe {
                @screen xl {
                  @apply my-auto h-full p-10;
                }
              }
            }
          }
        }
      }
    }

    &.success {
      .box {
        .body {
          .content {
            @apply flex-col;

            .message {
              @apply font-normal text-origin leading-7;

              p {
                & + p {
                  @apply mt-6;
                }
              }
            }

            .button {
              @apply mt-6;
            }
          }

        }
      }
    }
  }
}
</style>
